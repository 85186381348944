import { AsyncPipe, SlicePipe } from '@angular/common';
import { Component, inject, Injector, numberAttribute, OnInit, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { VideoService } from 'src/app/@domain/video/application/video.service';
import { Page } from '../../../../../@common/entities/page.interface';
import { computedAsync } from '../../../../../@common/rxjs/computed-async';
import { toNumber } from '../../../../../@common/util/to-number';
import { UserService } from '../../../../../@domain/user/application/user.service';
import { VideoInfo } from '../../../../../@generated';
import { PaginationComponent } from '../../../../global/pagination/pagination.component';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { CardGridComponent } from '../../../card-grid/card-grid.component';
import { VideoItemComponent } from '../../video-item/video-item.component';

@Component({
    selector: 'app-user-uploaded-videos',
    standalone: true,
    imports: [
        FaIconComponent,
        VideoItemComponent,
        PaginationComponent,
        SpinnerComponent,
        AsyncPipe,
        SlicePipe,
        CardGridComponent,
    ],
    styles: `
        :host {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        @media screen and (max-width: 1000px) {
            app-add-card {
                display: none;
            }
        }
    `,
    template: `
        @if (uploadedVideos(); as videoPage) {
            <app-card-grid [listView]="false">
                @for (video of videoPage.results | slice:0: !!isAdmin() ? 7 : 8; track video.id) {
                    <app-video-item [video]="video" [part]="video.parts[0]" [canDelete]="true"/>
                }
            </app-card-grid>

            <app-pagination [totalItems]="videoPage.count" [pageSize]="pageSize"/>
        } @else {
            <app-spinner/>
        }
    `
})
export class UserUploadedVideosComponent implements OnInit {
    private userService = inject(UserService);
    private videoService = inject(VideoService);
    private router = inject(Router);
    private injector = inject(Injector);

    public page = injectQueryParams('page', { transform: toNumber(1) });
    public uploadedVideos!: Signal<Page<VideoInfo> | undefined>;
    public isAdmin = this.userService.isAdmin;
    public pageSize = 8;

    public ngOnInit(): void {
        this.uploadedVideos = computedAsync(
            () => this.videoService.getUserUploadedVideos(this.pageSize, this.page() ?? 1),
            { injector: this.injector }
        );
    }

    goToUpload(): void {
        if (window.innerWidth < 1000) {
            confirm('Please use a desktop computer to upload content.')
        } else {
            this.router.navigate([ '/upload-video' ]);
        }
    }

}
