import { Routes } from '@angular/router';
import { AdminLayout } from '../@ui/layout/admin/admin.layout';
import AppLayout from '../@ui/layout/app/app.layout';
import { ADMIN_ROUTES } from './admin.routes';
import { APP_ROUTES } from './app.routes';
import { adminGuardFn } from './guards/admin.guard';
import { authGuardFn } from './guards/auth.guard';
import { slugGuardFn } from './guards/slug.guard';
import { AdminRoute } from './types/admin-route';
import { AppRoute } from './types/app-route';

export const ROUTES: Routes = [
    {
        path: '',
        component: AppLayout,
        loadChildren: () => APP_ROUTES
    },
    {
        path: AdminRoute.Admin,
        canActivate: [ authGuardFn, adminGuardFn ],
        component: AdminLayout,
        loadChildren: () => ADMIN_ROUTES
    },
    {
        path: `${ AppRoute.Embed }/${ AppRoute.Videos }/${ AppRoute.Slug }`,
        loadComponent: () => import('../@page/app/video/video.page'),
        data: { embed: true }
    },
    {
        path: AppRoute.Slug,
        pathMatch: 'full',
        canActivate: [ slugGuardFn ],
        loadComponent: () => import('../@page/app/not-found/not-found.page')
    },
    {
        path: '**',
        redirectTo: AppRoute.NotFound
    }
];
